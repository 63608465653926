<template>
  <div class="home">
    <div class="bar">
      <i class="iconfont icon-home"></i><span>李宸墨的个人博客</span>
      <span class="write-blog" @click="writeBlog" style="display: none">写文章</span>
      <!--      <span class="write-blog" @click="writeBlog">写文章</span>-->
    </div>
    <div class="content">
      <div class="content-body">
        <div class="con-item" v-for="blog in blogList" :key="blog.id" @click="showDetail(blog.id)">
          <div>标题：{{blog.blogTitle}}</div>
          <div>摘要：{{blog.blogSummary}}</div>
          <!--          <div id="editBlog" @click.stop="editBlog(blog.id)">编辑</div>-->
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-con">
        © 2021 李宸墨的个人博客
        <a href="https://beian.miit.gov.cn/" rel="noopener nofollow" target="_blank">豫ICP备2021037839号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    components: {},
    data() {
      return {
        blogList: []
      }
    },
    mounted() {
      this.getBlogList();
    },
    methods: {
      editBlog(blogid) {
        this.$router.push({name: 'addBlog', query: {blogId: blogid}})
      },
      showDetail(id) {
        this.$router.push({name: 'blogDetail', query: {blogId: id}})
      },
      getBlogList() {
        this.$http.post(
            'blog/getPageBlog',
            {
              "currentPage": 1,
              "pageSize": 10,
              "categoryList": []
            }
        ).then(res => {
          if (res.data.success) {
            this.blogList = res.data.data.list;
          }
        })
      },
      writeBlog() {
        this.$router.push("addBlog")
      }
    }
  }
</script>
<style>
  #editBlog {
    color: #1d7dfa;
    position: absolute;
    right: 2vw;
    top: 3vh;
  }

  .con-item {
    background-color: white;
    height: 6vh;
    width: 100%;
    margin: 2vh 2vw;
    border-radius: 5px;
    opacity: .7;
    padding: 2vh 1vh 0 1vh;
    cursor: pointer;
    position: relative;
  }

  .content-body {
    width: 50vw;
  }

  .content {
    display: flex;
    justify-content: center;
  }

  .write-blog {
    position: absolute;
    right: 2vw;
    cursor: pointer;
  }

  .footer-con {
    text-align: center;
  }

  .footer {
    width: 100%;
    height: 3vh;
    position: absolute;
    bottom: 0;
  }

  .icon-home {
    font-size: 3vh;
  }

  .bar {
    height: 5vh;
    background-color: black;
    opacity: .6;
    color: azure;
    /*flex 布局*/
    display: flex;
    /*实现垂直居中*/
    align-items: center;
    padding-left: 3vw;
  }

  .home {
    width: 100vw;
    height: 100vh;
    background-image: url("../assets/bg_02.jpg");
    background-size: cover;
  }
</style>
